<template>
<div id="div-home">
  <div class="box pb-3 pr" v-for="(dt, index) in res" :key="index">
    <button v-if="user.username === dt.username" @click="editPostFN" class="btn btn-pure closeModal"><i class="fas fa-edit"></i></button>
    <div class="user_info mt-3 mb-1">
      <div class="profile-image">
        <router-link :to="'/profile/' + dt.user_id + '/' + dt.username"><img v-lazy="'/users/' + Math.floor(dt.user_id / 1000) + '/' + dt.username + '/imgp.jpg'"></router-link>
      </div>
      <div><i class="fas fa-certificate badge" style="color:rgb(30,202,255)"><i class="fas fa-check"></i></i> <router-link :to="'/profile/' + dt.user_id + '/' + dt.username">{{ dt.username }}</router-link><br /><span class="data" v-data1>{{dt.updated_at}}</span></div>
    </div>
    <div class="pd-t-15 pd-l-15 pd-r-15 pd-b-0">
      <textarea id="post-edit124" v-if="editPost" class="form-control h-150" v-model="dt.description1"></textarea>
      <p v-else class="m-0" v-html="dt.description"></p>
    </div>
    <p v-if="editPost" class="m-15 m-b-0 text-center"><a class="button button2" @click="upPost">Atualizar</a></p>
    <div class="feed-img"><a class="mao" @dblclick="clickImg"><i class="fa">&#x1F525;</i><img :src="'/' + dt.image" /></a></div>
    <!--<div class="pl-2 ml-2"><a :class="{'cursor-inherit': !(dt.likes > 0)}" @click="viewLikes" ><i class="fas fa-fire"></i> {{ dt.likes || 0 }} Likes</a> <a :class="{'cursor-inherit': dt.comments == dt.comentarios.length}" @click="viewComments"><i class="far fa-comment ml-2"></i> {{ dt.comments || 0 }} Comentarios</a></div>-->
    <div class="user_info">
      <div class="profile-image" style="display:none">
        <img v-lazy="'/users/' + Math.floor(dt.user_id / 1000) + '/' + dt.username + '/imgp.jpg'">
      </div>
      <div class="comentario ml-3 mr-3 pr m-t-15">
        <input type="text" class="input-comentario" v-model="dt.comentario" placeholder="Escreva seu comentário" @keyup.enter="sendComment(dt)">
        <button :disabled="!dt.comentario.length" @click="sendComment(dt)" class="btn fas fa-paper-plane btn-comentario "></button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { posts, comments } from './../resources/fn'
import { error } from '../resources/functions'

export default {
  name: 'home',
  data () {
    return {
      res: [],
      editPost: false
    }
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  beforeMount () {
    this.SET_LOAD(true)
    posts(this.axios, { list: 'discovery' }).then((res) => {
      this.SET_LOAD()
      this.res = res.data.map(a => {
        a.comentario = ''
        return a
      })
    }, (res) => {
      this.SET_LOAD()
    })
  },
  methods: {
    ...mapMutations([
      'SET_LOAD'
    ]),
    sendComment (dt) {
      if (dt.comentario.length) {
        this.SET_LOAD(true)
        comments(this.axios, dt.user_id, dt.user, { post_id: dt.user_id_post, comment: dt.comentario }).then((res) => {
          this.SET_LOAD()
          dt.comentario = ''
        }, (res) => {
          error(this, 'nao foi possivel comentar')
          this.SET_LOAD()
        })
      } else {
        error(this, 'comentario nao pode ser vazio')
      }
    }
  }
}

</script>
